@import "global/variables";
@import "global/mixins";

@import "component-only/button";

// Single line snackbar fix for button color
.mat-mdc-snack-bar-action {
    color: $mat-light-color !important;
}
.isDarkTheme .mat-mdc-snack-bar-action {
    color: $mat-dark-color !important;
}
