@use "@angular/material" as mat;
.mat-mdc-dialog-container {
    margin-right: $spacer;
    margin-left: $spacer;
}

// Default text color change
.mat-mdc-dialog-container .mat-body-2 {
    color: $mat-dark-color;
}

.isDarkTheme .mat-mdc-dialog-container .mat-body-2 {
    color: $mat-light-color;
}

// ios fix for dialog height with bottom bar
.mat-mdc-dialog-content {
    max-height: 55vh !important;

    @include breakpoint {
        max-height: 65vh !important;
        min-width: 100%;
    }
}

// Typography
.mat-mdc-dialog-title,
.mat-mdc-dialog-content {
    //  @include mat.typography-level($custom-typography, title);
    font-family: var(--theme-font-body-family, $mat-font-family);
}

// Fix for icons in the context of a play button for a video and audio in a Dialog
.mat-mdc-dialog-content .ui .svg-inline--fa {
    vertical-align: 0 !important;
}
