html {
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, sans-serif;
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 16px / 24px Roboto, sans-serif;
  letter-spacing: 0.03125em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 22px Roboto, sans-serif;
  letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px Roboto, sans-serif;
  letter-spacing: 0.0178571429em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, sans-serif;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px Roboto, sans-serif;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px Roboto, sans-serif;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 50px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Roboto, sans-serif;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-title-text-font: Roboto, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-text-font: Roboto, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-card-title-text-font: Roboto, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #00bcd4;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #7c4dff;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #00bcd4;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #00bcd4;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #7c4dff;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #7c4dff;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #7c4dff;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #7c4dff;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: #000;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: #000;
  --mat-text-button-disabled-state-layer-color: #000;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #000;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-disabled-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #000;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-disabled-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: #000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #000;
  --mat-outlined-button-disabled-state-layer-color: #000;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #00bcd4;
  --mat-text-button-state-layer-color: #00bcd4;
  --mat-text-button-ripple-color: rgba(0, 188, 212, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #7c4dff;
  --mat-text-button-state-layer-color: #7c4dff;
  --mat-text-button-ripple-color: rgba(124, 77, 255, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #00bcd4;
  --mdc-filled-button-label-text-color: #000;
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #7c4dff;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #00bcd4;
  --mdc-protected-button-label-text-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #7c4dff;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #00bcd4;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #00bcd4;
  --mat-outlined-button-ripple-color: rgba(0, 188, 212, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #7c4dff;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #7c4dff;
  --mat-outlined-button-ripple-color: rgba(124, 77, 255, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: #000;
  --mat-fab-disabled-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: #000;
  --mat-fab-small-disabled-state-layer-color: #000;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #00bcd4;
  --mat-fab-foreground-color: #000;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #7c4dff;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #00bcd4;
  --mat-fab-small-foreground-color: #000;
  --mat-fab-small-state-layer-color: #000;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #7c4dff;
  --mat-fab-small-foreground-color: #fff;
  --mat-fab-small-state-layer-color: #fff;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: #fff;
  --mat-fab-small-state-layer-color: #fff;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: #000;
  --mat-icon-button-disabled-state-layer-color: #000;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #00bcd4;
  --mat-icon-button-state-layer-color: #00bcd4;
  --mat-icon-button-ripple-color: rgba(0, 188, 212, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #7c4dff;
  --mat-icon-button-state-layer-color: #7c4dff;
  --mat-icon-button-ripple-color: rgba(124, 77, 255, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

:root {
  --dark-primary-text-no-rgb: 0, 0, 0, 0.87;
  --dark-secondary-text-no-rgb: 0, 0, 0, 0.54;
  --dark-accent-text-no-rgb: 0, 0, 0, 0.87;
  --dark-warn-text-no-rgb: 0, 0, 0, 0.87;
  --dark-disabled-text-no-rgb: 0, 0, 0, 0.38;
  --dark-dividers-no-rgb: 0, 0, 0, 0.12;
  --dark-focused-no-rgb: 0, 0, 0, 0.12;
  --light-primary-text-no-rgb: 255, 255, 255, 1;
  --light-secondary-text-no-rgb: 255, 255, 255, 0.7;
  --light-accent-text-no-rgb: 255, 255, 255, 1;
  --light-warn-text-no-rgb: 255, 255, 255, 1;
  --light-disabled-text-no-rgb: 255, 255, 255, 0.5;
  --light-dividers-no-rgb: 255, 255, 255, 0.12;
  --light-focused-no-rgb: 255, 255, 255, 0.12;
  --dark-primary-text: rgba(var(--dark-primary-text-no-rgb));
  --dark-secondary-text: rgba(var(--dark-secondary-text-no-rgb));
  --dark-accent-text: rgba(var(--dark-accent-text-no-rgb));
  --dark-warn-text: rgba(var(--dark-warn-text-no-rgb));
  --dark-disabled-text: rgba(var(--dark-disabled-text-no-rgb));
  --dark-dividers: rgba(var(--dark-dividers-no-rgb));
  --dark-focused: rgba(var(--dark-focused-no-rgb));
  --light-primary-text: rgba(var(--light-primary-text-no-rgb));
  --light-secondary-text: rgba(var(--light-secondary-text-no-rgb));
  --light-accent-text: rgba(var(--light-accent-text-no-rgb));
  --light-warn-text: rgba(var(--light-warn-text-no-rgb));
  --light-disabled-text: rgba(var(--light-disabled-text-no-rgb));
  --light-dividers: rgba(var(--light-dividers-no-rgb));
  --light-focused: rgba(var(--light-focused-no-rgb));
  --dark-text-contrast: #000000;
  --light-text-contrast: var(--light-primary-text);
}

:root {
  --palette-primary-50-no-rgb: 225, 245, 254;
  --palette-primary-100-no-rgb: 179, 229, 252;
  --palette-primary-200-no-rgb: 129, 212, 250;
  --palette-primary-300-no-rgb: 79, 195, 247;
  --palette-primary-400-no-rgb: 41, 182, 246;
  --palette-primary-500-no-rgb: 3, 169, 244;
  --palette-primary-600-no-rgb: 3, 155, 229;
  --palette-primary-700-no-rgb: 2, 136, 209;
  --palette-primary-800-no-rgb: 2, 119, 189;
  --palette-primary-900-no-rgb: 1, 87, 155;
  --palette-primary-A100-no-rgb: 128, 216, 255;
  --palette-primary-A200-no-rgb: 64, 196, 255;
  --palette-primary-A400-no-rgb: 0, 176, 255;
  --palette-primary-A700-no-rgb: 0, 145, 234;
  --palette-primary-contrast-50-no-rgb: var(--dark-primary-text-no-rgb);
  --palette-primary-contrast-100-no-rgb: var(--dark-primary-text-no-rgb);
  --palette-primary-contrast-200-no-rgb: var(--dark-primary-text-no-rgb);
  --palette-primary-contrast-300-no-rgb: var(--dark-primary-text-no-rgb);
  --palette-primary-contrast-400-no-rgb: var(--dark-primary-text-no-rgb);
  --palette-primary-contrast-500-no-rgb: var(--dark-primary-text-no-rgb);
  --palette-primary-contrast-600-no-rgb: var(--dark-primary-text-no-rgb);
  --palette-primary-contrast-700-no-rgb: var(--dark-primary-text-no-rgb);
  --palette-primary-contrast-800-no-rgb: var(--dark-primary-text-no-rgb);
  --palette-primary-contrast-900-no-rgb: var(--dark-primary-text-no-rgb);
  --palette-primary-contrast-A100-no-rgb: var(--dark-primary-text-no-rgb);
  --palette-primary-contrast-A200-no-rgb: var(--dark-primary-text-no-rgb);
  --palette-primary-contrast-A400-no-rgb: var(--dark-primary-text-no-rgb);
  --palette-primary-contrast-A700-no-rgb: var(--dark-primary-text-no-rgb);
  --palette-primary-50: var(--palette-primary-50-rgb);
  --palette-primary-100: var(--palette-primary-100-rgb);
  --palette-primary-200: var(--palette-primary-200-rgb);
  --palette-primary-300: var(--palette-primary-300-rgb);
  --palette-primary-400: var(--palette-primary-400-rgb);
  --palette-primary-500: var(--palette-primary-500-rgb);
  --palette-primary-600: var(--palette-primary-600-rgb);
  --palette-primary-700: var(--palette-primary-700-rgb);
  --palette-primary-800: var(--palette-primary-800-rgb);
  --palette-primary-900: var(--palette-primary-900-rgb);
  --palette-primary-A100: var(--palette-primary-A100-rgb);
  --palette-primary-A200: var(--palette-primary-A200-rgb);
  --palette-primary-A400: var(--palette-primary-A400-rgb);
  --palette-primary-A700: var(--palette-primary-A700-rgb);
  --palette-primary-contrast-50: var(--palette-primary-contrast-50-rgb);
  --palette-primary-contrast-100: var(--palette-primary-contrast-100-rgb);
  --palette-primary-contrast-200: var(--palette-primary-contrast-200-rgb);
  --palette-primary-contrast-300: var(--palette-primary-contrast-300-rgb);
  --palette-primary-contrast-400: var(--palette-primary-contrast-400-rgb);
  --palette-primary-contrast-500: var(--palette-primary-contrast-500-rgb);
  --palette-primary-contrast-600: var(--palette-primary-contrast-600-rgb);
  --palette-primary-contrast-700: var(--palette-primary-contrast-700-rgb);
  --palette-primary-contrast-800: var(--palette-primary-contrast-800-rgb);
  --palette-primary-contrast-900: var(--palette-primary-contrast-900-rgb);
  --palette-primary-contrast-A100: var(--palette-primary-contrast-A100-rgb);
  --palette-primary-contrast-A200: var(--palette-primary-contrast-A200-rgb);
  --palette-primary-contrast-A400: var(--palette-primary-contrast-A400-rgb);
  --palette-primary-contrast-A700: var(--palette-primary-contrast-A700-rgb);
  --palette-accent-50-no-rgb: 252, 228, 236;
  --palette-accent-100-no-rgb: 248, 187, 208;
  --palette-accent-200-no-rgb: 244, 143, 177;
  --palette-accent-300-no-rgb: 240, 98, 146;
  --palette-accent-400-no-rgb: 236, 64, 122;
  --palette-accent-500-no-rgb: 233, 30, 99;
  --palette-accent-600-no-rgb: 216, 27, 96;
  --palette-accent-700-no-rgb: 194, 24, 91;
  --palette-accent-800-no-rgb: 173, 20, 87;
  --palette-accent-900-no-rgb: 136, 14, 79;
  --palette-accent-A100-no-rgb: 255, 128, 171;
  --palette-accent-A200-no-rgb: 255, 64, 129;
  --palette-accent-A400-no-rgb: 245, 0, 87;
  --palette-accent-A700-no-rgb: 197, 17, 98;
  --palette-accent-contrast-50-no-rgb: var(--dark-accent-text-no-rgb);
  --palette-accent-contrast-100-no-rgb: var(--dark-accent-text-no-rgb);
  --palette-accent-contrast-200-no-rgb: var(--dark-accent-text-no-rgb);
  --palette-accent-contrast-300-no-rgb: var(--dark-accent-text-no-rgb);
  --palette-accent-contrast-400-no-rgb: var(--dark-accent-text-no-rgb);
  --palette-accent-contrast-500-no-rgb: var(--dark-accent-text-no-rgb);
  --palette-accent-contrast-600-no-rgb: var(--dark-accent-text-no-rgb);
  --palette-accent-contrast-700-no-rgb: var(--light-accent-text-no-rgb);
  --palette-accent-contrast-800-no-rgb: var(--light-accent-text-no-rgb);
  --palette-accent-contrast-900-no-rgb: var(--light-accent-text-no-rgb);
  --palette-accent-contrast-A100-no-rgb: var(--dark-accent-text-no-rgb);
  --palette-accent-contrast-A200-no-rgb: var(--dark-accent-text-no-rgb);
  --palette-accent-contrast-A400-no-rgb: var(--dark-accent-text-no-rgb);
  --palette-accent-contrast-A700-no-rgb: var(--light-accent-text-no-rgb);
  --palette-accent-50: var(--palette-accent-50-rgb);
  --palette-accent-100: var(--palette-accent-100-rgb);
  --palette-accent-200: var(--palette-accent-200-rgb);
  --palette-accent-300: var(--palette-accent-300-rgb);
  --palette-accent-400: var(--palette-accent-400-rgb);
  --palette-accent-500: var(--palette-accent-500-rgb);
  --palette-accent-600: var(--palette-accent-600-rgb);
  --palette-accent-700: var(--palette-accent-700-rgb);
  --palette-accent-800: var(--palette-accent-800-rgb);
  --palette-accent-900: var(--palette-accent-900-rgb);
  --palette-accent-A100: var(--palette-accent-A100-rgb);
  --palette-accent-A200: var(--palette-accent-A200-rgb);
  --palette-accent-A400: var(--palette-accent-A400-rgb);
  --palette-accent-A700: var(--palette-accent-A700-rgb);
  --palette-accent-contrast-50: var(--palette-accent-contrast-50-rgb);
  --palette-accent-contrast-100: var(--palette-accent-contrast-100-rgb);
  --palette-accent-contrast-200: var(--palette-accent-contrast-200-rgb);
  --palette-accent-contrast-300: var(--palette-accent-contrast-300-rgb);
  --palette-accent-contrast-400: var(--palette-accent-contrast-400-rgb);
  --palette-accent-contrast-500: var(--palette-accent-contrast-500-rgb);
  --palette-accent-contrast-600: var(--palette-accent-contrast-600-rgb);
  --palette-accent-contrast-700: var(--palette-accent-contrast-700-rgb);
  --palette-accent-contrast-800: var(--palette-accent-contrast-800-rgb);
  --palette-accent-contrast-900: var(--palette-accent-contrast-900-rgb);
  --palette-accent-contrast-A100: var(--palette-accent-contrast-A100-rgb);
  --palette-accent-contrast-A200: var(--palette-accent-contrast-A200-rgb);
  --palette-accent-contrast-A400: var(--palette-accent-contrast-A400-rgb);
  --palette-accent-contrast-A700: var(--palette-accent-contrast-A700-rgb);
  --palette-warn-50-no-rgb: 255, 235, 238;
  --palette-warn-100-no-rgb: 255, 205, 210;
  --palette-warn-200-no-rgb: 239, 154, 154;
  --palette-warn-300-no-rgb: 229, 115, 115;
  --palette-warn-400-no-rgb: 239, 83, 80;
  --palette-warn-500-no-rgb: 244, 67, 54;
  --palette-warn-600-no-rgb: 229, 57, 53;
  --palette-warn-700-no-rgb: 211, 47, 47;
  --palette-warn-800-no-rgb: 198, 40, 40;
  --palette-warn-900-no-rgb: 183, 28, 28;
  --palette-warn-A100-no-rgb: 255, 138, 128;
  --palette-warn-A200-no-rgb: 255, 82, 82;
  --palette-warn-A400-no-rgb: 255, 23, 68;
  --palette-warn-A700-no-rgb: 213, 0, 0;
  --palette-warn-contrast-50-no-rgb: var(--dark-warn-text-no-rgb);
  --palette-warn-contrast-100-no-rgb: var(--dark-warn-text-no-rgb);
  --palette-warn-contrast-200-no-rgb: var(--dark-warn-text-no-rgb);
  --palette-warn-contrast-300-no-rgb: var(--dark-warn-text-no-rgb);
  --palette-warn-contrast-400-no-rgb: var(--dark-warn-text-no-rgb);
  --palette-warn-contrast-500-no-rgb: var(--dark-warn-text-no-rgb);
  --palette-warn-contrast-600-no-rgb: var(--dark-warn-text-no-rgb);
  --palette-warn-contrast-700-no-rgb: var(--dark-warn-text-no-rgb);
  --palette-warn-contrast-800-no-rgb: var(--dark-warn-text-no-rgb);
  --palette-warn-contrast-900-no-rgb: var(--dark-warn-text-no-rgb);
  --palette-warn-contrast-A100-no-rgb: var(--dark-warn-text-no-rgb);
  --palette-warn-contrast-A200-no-rgb: var(--dark-warn-text-no-rgb);
  --palette-warn-contrast-A400-no-rgb: var(--dark-warn-text-no-rgb);
  --palette-warn-contrast-A700-no-rgb: var(--dark-warn-text-no-rgb);
  --palette-warn-50: var(--palette-warn-50-rgb);
  --palette-warn-100: var(--palette-warn-100-rgb);
  --palette-warn-200: var(--palette-warn-200-rgb);
  --palette-warn-300: var(--palette-warn-300-rgb);
  --palette-warn-400: var(--palette-warn-400-rgb);
  --palette-warn-500: var(--palette-warn-500-rgb);
  --palette-warn-600: var(--palette-warn-600-rgb);
  --palette-warn-700: var(--palette-warn-700-rgb);
  --palette-warn-800: var(--palette-warn-800-rgb);
  --palette-warn-900: var(--palette-warn-900-rgb);
  --palette-warn-A100: var(--palette-warn-A100-rgb);
  --palette-warn-A200: var(--palette-warn-A200-rgb);
  --palette-warn-A400: var(--palette-warn-A400-rgb);
  --palette-warn-A700: var(--palette-warn-A700-rgb);
  --palette-warn-contrast-50: var(--palette-warn-contrast-50-rgb);
  --palette-warn-contrast-100: var(--palette-warn-contrast-100-rgb);
  --palette-warn-contrast-200: var(--palette-warn-contrast-200-rgb);
  --palette-warn-contrast-300: var(--palette-warn-contrast-300-rgb);
  --palette-warn-contrast-400: var(--palette-warn-contrast-400-rgb);
  --palette-warn-contrast-500: var(--palette-warn-contrast-500-rgb);
  --palette-warn-contrast-600: var(--palette-warn-contrast-600-rgb);
  --palette-warn-contrast-700: var(--palette-warn-contrast-700-rgb);
  --palette-warn-contrast-800: var(--palette-warn-contrast-800-rgb);
  --palette-warn-contrast-900: var(--palette-warn-contrast-900-rgb);
  --palette-warn-contrast-A100: var(--palette-warn-contrast-A100-rgb);
  --palette-warn-contrast-A200: var(--palette-warn-contrast-A200-rgb);
  --palette-warn-contrast-A400: var(--palette-warn-contrast-A400-rgb);
  --palette-warn-contrast-A700: var(--palette-warn-contrast-A700-rgb);
  --palette-primary-50-rgb: rgb(var(--palette-primary-50-no-rgb));
  --palette-primary-100-rgb: rgb(var(--palette-primary-100-no-rgb));
  --palette-primary-200-rgb: rgb(var(--palette-primary-200-no-rgb));
  --palette-primary-300-rgb: rgb(var(--palette-primary-300-no-rgb));
  --palette-primary-400-rgb: rgb(var(--palette-primary-400-no-rgb));
  --palette-primary-500-rgb: rgb(var(--palette-primary-500-no-rgb));
  --palette-primary-600-rgb: rgb(var(--palette-primary-600-no-rgb));
  --palette-primary-700-rgb: rgb(var(--palette-primary-700-no-rgb));
  --palette-primary-800-rgb: rgb(var(--palette-primary-800-no-rgb));
  --palette-primary-900-rgb: rgb(var(--palette-primary-900-no-rgb));
  --palette-primary-A100-rgb: rgb(var(--palette-primary-A100-no-rgb));
  --palette-primary-A200-rgb: rgb(var(--palette-primary-A200-no-rgb));
  --palette-primary-A400-rgb: rgb(var(--palette-primary-A400-no-rgb));
  --palette-primary-A700-rgb: rgb(var(--palette-primary-A700-no-rgb));
  --palette-primary-contrast-50-rgb: var(--dark-primary-text);
  --palette-primary-contrast-100-rgb: var(--dark-primary-text);
  --palette-primary-contrast-200-rgb: var(--dark-primary-text);
  --palette-primary-contrast-300-rgb: var(--dark-primary-text);
  --palette-primary-contrast-400-rgb: var(--dark-primary-text);
  --palette-primary-contrast-500-rgb: var(--light-primary-text);
  --palette-primary-contrast-600-rgb: var(--light-primary-text);
  --palette-primary-contrast-700-rgb: var(--light-primary-text);
  --palette-primary-contrast-800-rgb: var(--light-primary-text);
  --palette-primary-contrast-900-rgb: var(--light-primary-text);
  --palette-primary-contrast-A100-rgb: var(--light-primary-text);
  --palette-primary-contrast-A200-rgb: var(--light-primary-text);
  --palette-primary-contrast-A400-rgb: var(--light-primary-text);
  --palette-primary-contrast-A700-rgb: var(--light-primary-text);
  --palette-accent-50-rgb: rgb(var(--palette-accent-50-no-rgb));
  --palette-accent-100-rgb: rgb(var(--palette-accent-100-no-rgb));
  --palette-accent-200-rgb: rgb(var(--palette-accent-200-no-rgb));
  --palette-accent-300-rgb: rgb(var(--palette-accent-300-no-rgb));
  --palette-accent-400-rgb: rgb(var(--palette-accent-400-no-rgb));
  --palette-accent-500-rgb: rgb(var(--palette-accent-500-no-rgb));
  --palette-accent-600-rgb: rgb(var(--palette-accent-600-no-rgb));
  --palette-accent-700-rgb: rgb(var(--palette-accent-700-no-rgb));
  --palette-accent-800-rgb: rgb(var(--palette-accent-800-no-rgb));
  --palette-accent-900-rgb: rgb(var(--palette-accent-900-no-rgb));
  --palette-accent-A100-rgb: rgb(var(--palette-accent-A100));
  --palette-accent-A200-rgb: rgb(var(--palette-accent-A200));
  --palette-accent-A400-rgb: rgb(var(--palette-accent-A400));
  --palette-accent-A700-rgb: rgb(var(--palette-accent-A700));
  --palette-accent-contrast-50-rgb: var(--dark-accent-text);
  --palette-accent-contrast-100-rgb: var(--dark-accent-text);
  --palette-accent-contrast-200-rgb: var(--dark-accent-text);
  --palette-accent-contrast-300-rgb: var(--dark-accent-text);
  --palette-accent-contrast-400-rgb: var(--dark-accent-text);
  --palette-accent-contrast-500-rgb: var(--light-accent-text);
  --palette-accent-contrast-600-rgb: var(--light-accent-text);
  --palette-accent-contrast-700-rgb: var(--light-accent-text);
  --palette-accent-contrast-800-rgb: var(--light-accent-text);
  --palette-accent-contrast-900-rgb: var(--light-accent-text);
  --palette-accent-contrast-A100-rgb: var(--light-accent-text);
  --palette-accent-contrast-A200-rgb: var(--light-accent-text);
  --palette-accent-contrast-A400-rgb: var(--light-accent-text);
  --palette-accent-contrast-A700-rgb: var(--light-accent-text);
  --palette-warn-50-rgb: rgb(var(--palette-warn-50-no-rgb));
  --palette-warn-100-rgb: rgb(var(--palette-warn-100-no-rgb));
  --palette-warn-200-rgb: rgb(var(--palette-warn-200-no-rgb));
  --palette-warn-300-rgb: rgb(var(--palette-warn-300-no-rgb));
  --palette-warn-400-rgb: rgb(var(--palette-warn-400-no-rgb));
  --palette-warn-500-rgb: rgb(var(--palette-warn-500-no-rgb));
  --palette-warn-600-rgb: rgb(var(--palette-warn-600-no-rgb));
  --palette-warn-700-rgb: rgb(var(--palette-warn-700-no-rgb));
  --palette-warn-800-rgb: rgb(var(--palette-warn-800-no-rgb));
  --palette-warn-900-rgb: rgb(var(--palette-warn-900-no-rgb));
  --palette-warn-A100-rgb: rgb(var(--palette-warn-A100-no-rgb));
  --palette-warn-A200-rgb: rgb(var(--palette-warn-A200-no-rgb));
  --palette-warn-A400-rgb: rgb(var(--palette-warn-A400-no-rgb));
  --palette-warn-A700-rgb: rgb(var(--palette-warn-A700-no-rgb));
  --palette-warn-contrast-50-rgb: var(--dark-warn-text);
  --palette-warn-contrast-100-rgb: var(--dark-warn-text);
  --palette-warn-contrast-200-rgb: var(--dark-warn-text);
  --palette-warn-contrast-300-rgb: var(--dark-warn-text);
  --palette-warn-contrast-400-rgb: var(--dark-warn-text);
  --palette-warn-contrast-500-rgb: var(--light-warn-text);
  --palette-warn-contrast-600-rgb: var(--light-warn-text);
  --palette-warn-contrast-700-rgb: var(--light-warn-text);
  --palette-warn-contrast-800-rgb: var(--light-warn-text);
  --palette-warn-contrast-900-rgb: var(--light-warn-text);
  --palette-warn-contrast-A100-rgb: var(--light-warn-text);
  --palette-warn-contrast-A200-rgb: var(--light-warn-text);
  --palette-warn-contrast-A400-rgb: var(--light-warn-text);
  --palette-warn-contrast-A700-rgb: var(--light-warn-text);
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: var(--palette-primary-500);
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: var(--palette-accent-500);
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: var(--palette-warn-500);
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--palette-primary-500);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--palette-primary-500);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--palette-accent-500);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--palette-accent-500);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--palette-accent-500);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--palette-accent-500);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--palette-warn-500);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--palette-warn-500);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: Roboto, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--palette-primary-500);
  --mdc-linear-progress-track-color: var(--palette-primary-500);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: var(--palette-accent-500);
  --mdc-linear-progress-track-color: var(--palette-accent-500);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: var(--palette-warn-500);
  --mdc-linear-progress-track-color: var(--palette-warn-500);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: var(--palette-primary-500);
  --mdc-filled-text-field-focus-active-indicator-color: var(--palette-primary-500);
  --mdc-filled-text-field-focus-label-text-color: var(--palette-primary-500);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: var(--palette-warn-500);
  --mdc-filled-text-field-error-focus-label-text-color: var(--palette-warn-500);
  --mdc-filled-text-field-error-label-text-color: var(--palette-warn-500);
  --mdc-filled-text-field-error-caret-color: var(--palette-warn-500);
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: var(--palette-warn-500);
  --mdc-filled-text-field-error-focus-active-indicator-color: var(--palette-warn-500);
  --mdc-filled-text-field-error-hover-active-indicator-color: var(--palette-warn-500);
  --mdc-outlined-text-field-caret-color: var(--palette-primary-500);
  --mdc-outlined-text-field-focus-outline-color: var(--palette-primary-500);
  --mdc-outlined-text-field-focus-label-text-color: var(--palette-primary-500);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: var(--palette-warn-500);
  --mdc-outlined-text-field-error-focus-label-text-color: var(--palette-warn-500);
  --mdc-outlined-text-field-error-label-text-color: var(--palette-warn-500);
  --mdc-outlined-text-field-error-hover-label-text-color: var(--palette-warn-500);
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: var(--palette-warn-500);
  --mdc-outlined-text-field-error-hover-outline-color: var(--palette-warn-500);
  --mdc-outlined-text-field-error-outline-color: var(--palette-warn-500);
  --mat-form-field-focus-select-arrow-color: var(--palette-primary-500);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: var(--palette-warn-500);
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: var(--palette-accent-500);
  --mdc-filled-text-field-focus-active-indicator-color: var(--palette-accent-500);
  --mdc-filled-text-field-focus-label-text-color: var(--palette-accent-500);
  --mdc-outlined-text-field-caret-color: var(--palette-accent-500);
  --mdc-outlined-text-field-focus-outline-color: var(--palette-accent-500);
  --mdc-outlined-text-field-focus-label-text-color: var(--palette-accent-500);
  --mat-form-field-focus-select-arrow-color: var(--palette-accent-500);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: var(--palette-warn-500);
  --mdc-filled-text-field-focus-active-indicator-color: var(--palette-warn-500);
  --mdc-filled-text-field-focus-label-text-color: var(--palette-warn-500);
  --mdc-outlined-text-field-caret-color: var(--palette-warn-500);
  --mdc-outlined-text-field-focus-outline-color: var(--palette-warn-500);
  --mdc-outlined-text-field-focus-label-text-color: var(--palette-warn-500);
  --mat-form-field-focus-select-arrow-color: var(--palette-warn-500);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: var(--palette-primary-500);
  --mat-select-invalid-arrow-color: var(--palette-warn-500);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: var(--palette-accent-500);
  --mat-select-invalid-arrow-color: var(--palette-warn-500);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: var(--palette-warn-500);
  --mat-select-invalid-arrow-color: var(--palette-warn-500);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: var(--palette-primary-contrast-500);
  --mdc-chip-elevated-container-color: var(--palette-primary-500);
  --mdc-chip-elevated-selected-container-color: var(--palette-primary-500);
  --mdc-chip-elevated-disabled-container-color: var(--palette-primary-500);
  --mdc-chip-flat-disabled-selected-container-color: var(--palette-primary-500);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--palette-primary-contrast-500);
  --mdc-chip-selected-label-text-color: var(--palette-primary-contrast-500);
  --mdc-chip-with-icon-icon-color: var(--palette-primary-contrast-500);
  --mdc-chip-with-icon-disabled-icon-color: var(--palette-primary-contrast-500);
  --mdc-chip-with-icon-selected-icon-color: var(--palette-primary-contrast-500);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--palette-primary-contrast-500);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--palette-primary-contrast-500);
  --mat-chip-selected-disabled-trailing-icon-color: var(--palette-primary-contrast-500);
  --mat-chip-selected-trailing-icon-color: var(--palette-primary-contrast-500);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: var(--palette-accent-contrast-500);
  --mdc-chip-elevated-container-color: var(--palette-accent-500);
  --mdc-chip-elevated-selected-container-color: var(--palette-accent-500);
  --mdc-chip-elevated-disabled-container-color: var(--palette-accent-500);
  --mdc-chip-flat-disabled-selected-container-color: var(--palette-accent-500);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--palette-accent-contrast-500);
  --mdc-chip-selected-label-text-color: var(--palette-accent-contrast-500);
  --mdc-chip-with-icon-icon-color: var(--palette-accent-contrast-500);
  --mdc-chip-with-icon-disabled-icon-color: var(--palette-accent-contrast-500);
  --mdc-chip-with-icon-selected-icon-color: var(--palette-accent-contrast-500);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--palette-accent-contrast-500);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--palette-accent-contrast-500);
  --mat-chip-selected-disabled-trailing-icon-color: var(--palette-accent-contrast-500);
  --mat-chip-selected-trailing-icon-color: var(--palette-accent-contrast-500);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: var(--palette-warn-contrast-500);
  --mdc-chip-elevated-container-color: var(--palette-warn-500);
  --mdc-chip-elevated-selected-container-color: var(--palette-warn-500);
  --mdc-chip-elevated-disabled-container-color: var(--palette-warn-500);
  --mdc-chip-flat-disabled-selected-container-color: var(--palette-warn-500);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--palette-warn-contrast-500);
  --mdc-chip-selected-label-text-color: var(--palette-warn-contrast-500);
  --mdc-chip-with-icon-icon-color: var(--palette-warn-contrast-500);
  --mdc-chip-with-icon-disabled-icon-color: var(--palette-warn-contrast-500);
  --mdc-chip-with-icon-selected-icon-color: var(--palette-warn-contrast-500);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--palette-warn-contrast-500);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--palette-warn-contrast-500);
  --mat-chip-selected-disabled-trailing-icon-color: var(--palette-warn-contrast-500);
  --mat-chip-selected-trailing-icon-color: var(--palette-warn-contrast-500);
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: var(--palette-primary-600);
  --mdc-switch-selected-handle-color: var(--palette-primary-600);
  --mdc-switch-selected-hover-state-layer-color: var(--palette-primary-600);
  --mdc-switch-selected-pressed-state-layer-color: var(--palette-primary-600);
  --mdc-switch-selected-focus-handle-color: var(--palette-primary-900);
  --mdc-switch-selected-hover-handle-color: var(--palette-primary-900);
  --mdc-switch-selected-pressed-handle-color: var(--palette-primary-900);
  --mdc-switch-selected-focus-track-color: var(--palette-primary-300);
  --mdc-switch-selected-hover-track-color: var(--palette-primary-300);
  --mdc-switch-selected-pressed-track-color: var(--palette-primary-300);
  --mdc-switch-selected-track-color: var(--palette-primary-300);
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--palette-accent-600);
  --mdc-switch-selected-handle-color: var(--palette-accent-600);
  --mdc-switch-selected-hover-state-layer-color: var(--palette-accent-600);
  --mdc-switch-selected-pressed-state-layer-color: var(--palette-accent-600);
  --mdc-switch-selected-focus-handle-color: var(--palette-accent-900);
  --mdc-switch-selected-hover-handle-color: var(--palette-accent-900);
  --mdc-switch-selected-pressed-handle-color: var(--palette-accent-900);
  --mdc-switch-selected-focus-track-color: var(--palette-accent-300);
  --mdc-switch-selected-hover-track-color: var(--palette-accent-300);
  --mdc-switch-selected-pressed-track-color: var(--palette-accent-300);
  --mdc-switch-selected-track-color: var(--palette-accent-300);
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: var(--palette-warn-600);
  --mdc-switch-selected-handle-color: var(--palette-warn-600);
  --mdc-switch-selected-hover-state-layer-color: var(--palette-warn-600);
  --mdc-switch-selected-pressed-state-layer-color: var(--palette-warn-600);
  --mdc-switch-selected-focus-handle-color: var(--palette-warn-900);
  --mdc-switch-selected-hover-handle-color: var(--palette-warn-900);
  --mdc-switch-selected-pressed-handle-color: var(--palette-warn-900);
  --mdc-switch-selected-focus-track-color: var(--palette-warn-300);
  --mdc-switch-selected-hover-track-color: var(--palette-warn-300);
  --mdc-switch-selected-pressed-track-color: var(--palette-warn-300);
  --mdc-switch-selected-track-color: var(--palette-warn-300);
}

html {
  --mdc-switch-state-layer-size: 40px;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--palette-primary-500);
  --mdc-radio-selected-hover-icon-color: var(--palette-primary-500);
  --mdc-radio-selected-icon-color: var(--palette-primary-500);
  --mdc-radio-selected-pressed-icon-color: var(--palette-primary-500);
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: var(--palette-primary-500);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--palette-accent-500);
  --mdc-radio-selected-hover-icon-color: var(--palette-accent-500);
  --mdc-radio-selected-icon-color: var(--palette-accent-500);
  --mdc-radio-selected-pressed-icon-color: var(--palette-accent-500);
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: var(--palette-accent-500);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--palette-warn-500);
  --mdc-radio-selected-hover-icon-color: var(--palette-warn-500);
  --mdc-radio-selected-icon-color: var(--palette-warn-500);
  --mdc-radio-selected-pressed-icon-color: var(--palette-warn-500);
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: var(--palette-warn-500);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: var(--palette-primary-500);
  --mdc-slider-focus-handle-color: var(--palette-primary-500);
  --mdc-slider-hover-handle-color: var(--palette-primary-500);
  --mdc-slider-active-track-color: var(--palette-primary-500);
  --mdc-slider-inactive-track-color: var(--palette-primary-500);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--palette-primary-500);
  --mdc-slider-with-tick-marks-active-container-color: var(--palette-primary-contrast-500);
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: var(--palette-primary-500);
  --mat-slider-hover-state-layer-color: rgba(var(--palette-primary-500), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--palette-primary-500), 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: var(--palette-accent-500);
  --mat-slider-hover-state-layer-color: rgba(var(--palette-accent-500), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--palette-accent-500), 0.2);
  --mdc-slider-handle-color: var(--palette-accent-500);
  --mdc-slider-focus-handle-color: var(--palette-accent-500);
  --mdc-slider-hover-handle-color: var(--palette-accent-500);
  --mdc-slider-active-track-color: var(--palette-accent-500);
  --mdc-slider-inactive-track-color: var(--palette-accent-500);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--palette-accent-500);
  --mdc-slider-with-tick-marks-active-container-color: var(--palette-accent-contrast-500);
}
html .mat-warn {
  --mat-slider-ripple-color: var(--palette-warn-500);
  --mat-slider-hover-state-layer-color: rgba(var(--palette-warn-500), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--palette-warn-500), 0.2);
  --mdc-slider-handle-color: var(--palette-warn-500);
  --mdc-slider-focus-handle-color: var(--palette-warn-500);
  --mdc-slider-hover-handle-color: var(--palette-warn-500);
  --mdc-slider-active-track-color: var(--palette-warn-500);
  --mdc-slider-inactive-track-color: var(--palette-warn-500);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--palette-warn-500);
  --mdc-slider-with-tick-marks-active-container-color: var(--palette-warn-contrast-500);
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--palette-primary-500);
  --mdc-radio-selected-hover-icon-color: var(--palette-primary-500);
  --mdc-radio-selected-icon-color: var(--palette-primary-500);
  --mdc-radio-selected-pressed-icon-color: var(--palette-primary-500);
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--palette-accent-500);
  --mdc-radio-selected-hover-icon-color: var(--palette-accent-500);
  --mdc-radio-selected-icon-color: var(--palette-accent-500);
  --mdc-radio-selected-pressed-icon-color: var(--palette-accent-500);
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--palette-warn-500);
  --mdc-radio-selected-hover-icon-color: var(--palette-warn-500);
  --mdc-radio-selected-icon-color: var(--palette-warn-500);
  --mdc-radio-selected-pressed-icon-color: var(--palette-warn-500);
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--palette-primary-500);
  --mdc-checkbox-selected-hover-icon-color: var(--palette-primary-500);
  --mdc-checkbox-selected-icon-color: var(--palette-primary-500);
  --mdc-checkbox-selected-pressed-icon-color: var(--palette-primary-500);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--palette-primary-500);
  --mdc-checkbox-selected-hover-state-layer-color: var(--palette-primary-500);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--palette-primary-500);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--palette-accent-500);
  --mdc-checkbox-selected-hover-icon-color: var(--palette-accent-500);
  --mdc-checkbox-selected-icon-color: var(--palette-accent-500);
  --mdc-checkbox-selected-pressed-icon-color: var(--palette-accent-500);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--palette-accent-500);
  --mdc-checkbox-selected-hover-state-layer-color: var(--palette-accent-500);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--palette-accent-500);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--palette-warn-500);
  --mdc-checkbox-selected-hover-icon-color: var(--palette-warn-500);
  --mdc-checkbox-selected-icon-color: var(--palette-warn-500);
  --mdc-checkbox-selected-pressed-icon-color: var(--palette-warn-500);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--palette-warn-500);
  --mdc-checkbox-selected-hover-state-layer-color: var(--palette-warn-500);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--palette-warn-500);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--palette-primary-500);
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--palette-primary-500);
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--palette-primary-500);
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--palette-primary-500);
  --mat-tab-header-active-ripple-color: var(--palette-primary-500);
  --mat-tab-header-inactive-ripple-color: var(--palette-primary-500);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--palette-primary-500);
  --mat-tab-header-active-hover-label-text-color: var(--palette-primary-500);
  --mat-tab-header-active-focus-indicator-color: var(--palette-primary-500);
  --mat-tab-header-active-hover-indicator-color: var(--palette-primary-500);
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: var(--palette-accent-500);
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--palette-accent-500);
  --mat-tab-header-active-ripple-color: var(--palette-accent-500);
  --mat-tab-header-inactive-ripple-color: var(--palette-accent-500);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--palette-accent-500);
  --mat-tab-header-active-hover-label-text-color: var(--palette-accent-500);
  --mat-tab-header-active-focus-indicator-color: var(--palette-accent-500);
  --mat-tab-header-active-hover-indicator-color: var(--palette-accent-500);
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: var(--palette-warn-500);
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--palette-warn-500);
  --mat-tab-header-active-ripple-color: var(--palette-warn-500);
  --mat-tab-header-inactive-ripple-color: var(--palette-warn-500);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--palette-warn-500);
  --mat-tab-header-active-hover-label-text-color: var(--palette-warn-500);
  --mat-tab-header-active-focus-indicator-color: var(--palette-warn-500);
  --mat-tab-header-active-hover-indicator-color: var(--palette-warn-500);
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: var(--palette-primary-500);
  --mat-tab-header-with-background-foreground-color: var(--palette-primary-contrast-500);
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: var(--palette-accent-500);
  --mat-tab-header-with-background-foreground-color: var(--palette-accent-contrast-500);
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: var(--palette-warn-500);
  --mat-tab-header-with-background-foreground-color: var(--palette-warn-contrast-500);
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--palette-accent-500);
  --mdc-checkbox-selected-hover-icon-color: var(--palette-accent-500);
  --mdc-checkbox-selected-icon-color: var(--palette-accent-500);
  --mdc-checkbox-selected-pressed-icon-color: var(--palette-accent-500);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--palette-accent-500);
  --mdc-checkbox-selected-hover-state-layer-color: var(--palette-accent-500);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--palette-accent-500);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--palette-primary-500);
  --mdc-checkbox-selected-hover-icon-color: var(--palette-primary-500);
  --mdc-checkbox-selected-icon-color: var(--palette-primary-500);
  --mdc-checkbox-selected-pressed-icon-color: var(--palette-primary-500);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--palette-primary-500);
  --mdc-checkbox-selected-hover-state-layer-color: var(--palette-primary-500);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--palette-primary-500);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--palette-warn-500);
  --mdc-checkbox-selected-hover-icon-color: var(--palette-warn-500);
  --mdc-checkbox-selected-icon-color: var(--palette-warn-500);
  --mdc-checkbox-selected-pressed-icon-color: var(--palette-warn-500);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--palette-warn-500);
  --mdc-checkbox-selected-hover-state-layer-color: var(--palette-warn-500);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--palette-warn-500);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: #000;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: #000;
  --mat-text-button-disabled-state-layer-color: #000;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #000;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-disabled-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #000;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-disabled-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: #000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #000;
  --mat-outlined-button-disabled-state-layer-color: #000;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: var(--palette-primary-500);
  --mat-text-button-state-layer-color: var(--palette-primary-500);
  --mat-text-button-ripple-color: var(--palette-primary-500);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: var(--palette-accent-500);
  --mat-text-button-state-layer-color: var(--palette-accent-500);
  --mat-text-button-ripple-color: var(--palette-accent-500);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: var(--palette-warn-500);
  --mat-text-button-state-layer-color: var(--palette-warn-500);
  --mat-text-button-ripple-color: var(--palette-warn-500);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: var(--palette-primary-500);
  --mdc-filled-button-label-text-color: #000;
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: var(--palette-accent-500);
  --mdc-filled-button-label-text-color: #000;
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: var(--palette-warn-500);
  --mdc-filled-button-label-text-color: #000;
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: var(--palette-primary-500);
  --mdc-protected-button-label-text-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: var(--palette-accent-500);
  --mdc-protected-button-label-text-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: var(--palette-warn-500);
  --mdc-protected-button-label-text-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: var(--palette-primary-500);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: var(--palette-primary-500);
  --mat-outlined-button-ripple-color: var(--palette-primary-500);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: var(--palette-accent-500);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: var(--palette-accent-500);
  --mat-outlined-button-ripple-color: var(--palette-accent-500);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: var(--palette-warn-500);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: var(--palette-warn-500);
  --mat-outlined-button-ripple-color: var(--palette-warn-500);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: #000;
  --mat-icon-button-disabled-state-layer-color: #000;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: var(--palette-primary-500);
  --mat-icon-button-state-layer-color: var(--palette-primary-500);
  --mat-icon-button-ripple-color: var(--palette-primary-500);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: var(--palette-accent-500);
  --mat-icon-button-state-layer-color: var(--palette-accent-500);
  --mat-icon-button-ripple-color: var(--palette-accent-500);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: var(--palette-warn-500);
  --mat-icon-button-state-layer-color: var(--palette-warn-500);
  --mat-icon-button-ripple-color: var(--palette-warn-500);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: #000;
  --mat-fab-disabled-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: #000;
  --mat-fab-small-disabled-state-layer-color: #000;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: var(--palette-primary-500);
  --mat-fab-foreground-color: #000;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: var(--palette-accent-500);
  --mat-fab-foreground-color: #000;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: var(--palette-warn-500);
  --mat-fab-foreground-color: #000;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: var(--palette-primary-500);
  --mat-fab-small-foreground-color: #000;
  --mat-fab-small-state-layer-color: #000;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: var(--palette-accent-500);
  --mat-fab-small-foreground-color: #000;
  --mat-fab-small-state-layer-color: #000;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: var(--palette-warn-500);
  --mat-fab-small-foreground-color: #000;
  --mat-fab-small-state-layer-color: #000;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: var(--palette-accent-500);
}

html {
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: var(--palette-primary-500);
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: var(--palette-accent-500);
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: var(--palette-warn-500);
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: var(--palette-primary-500);
  --mat-badge-text-color: var(--palette-primary-contrast-500);
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: var(--palette-accent-500);
  --mat-badge-text-color: var(--palette-accent-contrast-500);
}

.mat-badge-warn {
  --mat-badge-background-color: var(--palette-warn-500);
  --mat-badge-text-color: var(--palette-warn-contrast-500);
}

html {
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--palette-primary-contrast-500);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--palette-primary-500);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 0, 0, 0.26);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--palette-primary-contrast-500);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--palette-primary-500);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--palette-primary-500);
  --mat-datepicker-toggle-active-state-icon-color: var(--palette-primary-500);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--palette-primary-500), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: #000;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--palette-accent-contrast-500);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--palette-accent-500);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 0, 0, 0.26);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--palette-accent-contrast-500);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--palette-accent-500);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--palette-accent-500);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--palette-accent-500), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--palette-warn-contrast-500);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--palette-warn-500);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 0, 0, 0.26);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--palette-warn-contrast-500);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--palette-warn-500);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--palette-warn-500);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--palette-warn-500), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: var(--palette-accent-500);
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: var(--palette-warn-500);
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: var(--palette-primary-500);
}
.mat-icon.mat-accent {
  --mat-icon-color: var(--palette-accent-500);
}
.mat-icon.mat-warn {
  --mat-icon-color: var(--palette-warn-500);
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: var(--palette-primary-contrast-500);
  --mat-stepper-header-selected-state-icon-background-color: var(--palette-primary-500);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--palette-primary-contrast-500);
  --mat-stepper-header-done-state-icon-background-color: var(--palette-primary-500);
  --mat-stepper-header-done-state-icon-foreground-color: var(--palette-primary-contrast-500);
  --mat-stepper-header-edit-state-icon-background-color: var(--palette-primary-500);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--palette-primary-contrast-500);
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: var(--palette-warn-500);
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: var(--palette-warn-500);
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: var(--palette-accent-contrast-500);
  --mat-stepper-header-selected-state-icon-background-color: var(--palette-accent-500);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--palette-accent-contrast-500);
  --mat-stepper-header-done-state-icon-background-color: var(--palette-accent-500);
  --mat-stepper-header-done-state-icon-foreground-color: var(--palette-accent-contrast-500);
  --mat-stepper-header-edit-state-icon-background-color: var(--palette-accent-500);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--palette-accent-contrast-500);
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: var(--palette-warn-contrast-500);
  --mat-stepper-header-selected-state-icon-background-color: var(--palette-warn-500);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--palette-warn-contrast-500);
  --mat-stepper-header-done-state-icon-background-color: var(--palette-warn-500);
  --mat-stepper-header-done-state-icon-foreground-color: var(--palette-warn-contrast-500);
  --mat-stepper-header-edit-state-icon-background-color: var(--palette-warn-500);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--palette-warn-contrast-500);
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: var(--palette-primary-500);
  --mat-toolbar-container-text-color: var(--palette-primary-contrast-500);
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: var(--palette-accent-500);
  --mat-toolbar-container-text-color: var(--palette-accent-contrast-500);
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: var(--palette-warn-500);
  --mat-toolbar-container-text-color: var(--palette-warn-contrast-500);
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Roboto, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: Roboto, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, sans-serif;
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 16px / 24px Roboto, sans-serif;
  letter-spacing: 0.03125em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 22px Roboto, sans-serif;
  letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px Roboto, sans-serif;
  letter-spacing: 0.0178571429em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, sans-serif;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px Roboto, sans-serif;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px Roboto, sans-serif;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 50px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Roboto, sans-serif;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

.isDarkTheme {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: var(--palette-primary-500);
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: var(--palette-accent-500);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--palette-accent-500);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #303030;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #424242;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #424242;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: var(--palette-primary-500);
  --mdc-filled-text-field-focus-active-indicator-color: var(--palette-primary-500);
  --mdc-filled-text-field-focus-label-text-color: var(--palette-primary-500);
  --mdc-filled-text-field-container-color: #4a4a4a;
  --mdc-filled-text-field-disabled-container-color: #464646;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: var(--palette-warn-500);
  --mdc-filled-text-field-error-focus-label-text-color: var(--palette-warn-500);
  --mdc-filled-text-field-error-label-text-color: var(--palette-warn-500);
  --mdc-filled-text-field-error-caret-color: var(--palette-warn-500);
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: var(--palette-warn-500);
  --mdc-filled-text-field-error-focus-active-indicator-color: var(--palette-warn-500);
  --mdc-filled-text-field-error-hover-active-indicator-color: var(--palette-warn-500);
  --mdc-outlined-text-field-caret-color: var(--palette-primary-500);
  --mdc-outlined-text-field-focus-outline-color: var(--palette-primary-500);
  --mdc-outlined-text-field-focus-label-text-color: var(--palette-primary-500);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: var(--palette-warn-500);
  --mdc-outlined-text-field-error-focus-label-text-color: var(--palette-warn-500);
  --mdc-outlined-text-field-error-label-text-color: var(--palette-warn-500);
  --mdc-outlined-text-field-error-hover-label-text-color: var(--palette-warn-500);
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: var(--palette-warn-500);
  --mdc-outlined-text-field-error-hover-outline-color: var(--palette-warn-500);
  --mdc-outlined-text-field-error-outline-color: var(--palette-warn-500);
  --mat-form-field-focus-select-arrow-color: var(--palette-primary-500);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: var(--palette-warn-500);
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: var(--palette-primary-500);
  --mat-select-invalid-arrow-color: var(--palette-warn-500);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #424242;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: var(--palette-primary-300);
  --mdc-switch-selected-handle-color: var(--palette-primary-300);
  --mdc-switch-selected-hover-state-layer-color: var(--palette-primary-300);
  --mdc-switch-selected-pressed-state-layer-color: var(--palette-primary-300);
  --mdc-switch-selected-focus-handle-color: var(--palette-primary-200);
  --mdc-switch-selected-hover-handle-color: var(--palette-primary-200);
  --mdc-switch-selected-pressed-handle-color: var(--palette-primary-200);
  --mdc-switch-selected-focus-track-color: var(--palette-primary-600);
  --mdc-switch-selected-hover-track-color: var(--palette-primary-600);
  --mdc-switch-selected-pressed-track-color: var(--palette-primary-600);
  --mdc-switch-selected-track-color: var(--palette-primary-600);
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: var(--palette-primary-500);
  --mdc-slider-focus-handle-color: var(--palette-primary-500);
  --mdc-slider-hover-handle-color: var(--palette-primary-500);
  --mdc-slider-active-track-color: var(--palette-primary-500);
  --mdc-slider-inactive-track-color: var(--palette-primary-500);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--palette-primary-500);
  --mdc-slider-with-tick-marks-active-container-color: var(--palette-primary-contrast-500);
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: var(--palette-primary-500);
  --mat-slider-hover-state-layer-color: rgba(var(--palette-primary-500), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--palette-primary-500), 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #424242;
  --mat-menu-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #424242;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--palette-accent-500);
  --mdc-checkbox-selected-hover-icon-color: var(--palette-accent-500);
  --mdc-checkbox-selected-icon-color: var(--palette-accent-500);
  --mdc-checkbox-selected-pressed-icon-color: var(--palette-accent-500);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--palette-accent-500);
  --mdc-checkbox-selected-hover-state-layer-color: var(--palette-accent-500);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--palette-accent-500);
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: #fff;
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-text-button-state-layer-color: #fff;
  --mat-text-button-disabled-state-layer-color: #fff;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: #fff;
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-disabled-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: #fff;
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-disabled-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-label-text-color: #fff;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #fff;
  --mat-outlined-button-disabled-state-layer-color: #fff;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: #fff;
  --mat-icon-button-disabled-state-layer-color: #fff;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #424242;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-disabled-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
  --mdc-fab-small-container-color: #424242;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: #fff;
  --mat-fab-small-disabled-state-layer-color: #fff;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d9d9d9;
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #424242;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: var(--palette-primary-500);
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: var(--palette-primary-500);
  --mat-badge-text-color: var(--palette-primary-contrast-500);
  --mat-badge-disabled-state-background-color: #6e6e6e;
  --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #424242;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.5);
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.7);
  --mat-legacy-button-toggle-selected-state-background-color: #212121;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #424242;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #212121;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-standard-button-toggle-disabled-state-background-color: #424242;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-divider-color: #595959;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: var(--palette-primary-contrast-500);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--palette-primary-500);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--palette-primary-contrast-500);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--palette-primary-500);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--palette-primary-500);
  --mat-datepicker-toggle-active-state-icon-color: var(--palette-primary-500);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--palette-primary-500), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-period-button-text-color: #fff;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: #424242;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #424242;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
  --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #424242;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #303030;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(189, 189, 189, 0.6);
  --mat-stepper-header-icon-foreground-color: var(--palette-primary-contrast-500);
  --mat-stepper-header-selected-state-icon-background-color: var(--palette-primary-500);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--palette-primary-contrast-500);
  --mat-stepper-header-done-state-icon-background-color: var(--palette-primary-500);
  --mat-stepper-header-done-state-icon-foreground-color: var(--palette-primary-contrast-500);
  --mat-stepper-header-edit-state-icon-background-color: var(--palette-primary-500);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--palette-primary-contrast-500);
  --mat-stepper-container-color: #424242;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: var(--palette-warn-500);
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-error-state-icon-foreground-color: var(--palette-warn-500);
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #c6c6c6;
  --mat-toolbar-container-background-color: #212121;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #424242;
  --mat-tree-node-text-color: white;
}
.isDarkTheme .mat-accent {
  --mat-option-selected-state-label-text-color: var(--palette-accent-500);
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.isDarkTheme .mat-warn {
  --mat-option-selected-state-label-text-color: var(--palette-warn-500);
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.isDarkTheme .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--palette-primary-500);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--palette-primary-500);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.isDarkTheme .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--palette-accent-500);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--palette-accent-500);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.isDarkTheme .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--palette-warn-500);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--palette-warn-500);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.isDarkTheme .mat-elevation-z0, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z1, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z2, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z3, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z4, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z5, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z6, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z7, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z8, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z9, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z10, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z11, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z12, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z13, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z14, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z15, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z16, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z17, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z18, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z19, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z20, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z21, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z22, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z23, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.isDarkTheme .mat-elevation-z24, .isDarkTheme .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.isDarkTheme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--palette-primary-500);
  --mdc-linear-progress-track-color: var(--palette-primary-500);
}
.isDarkTheme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: var(--palette-accent-500);
  --mdc-linear-progress-track-color: var(--palette-accent-500);
}
.isDarkTheme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: var(--palette-warn-500);
  --mdc-linear-progress-track-color: var(--palette-warn-500);
}
.isDarkTheme .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: var(--palette-accent-500);
  --mdc-filled-text-field-focus-active-indicator-color: var(--palette-accent-500);
  --mdc-filled-text-field-focus-label-text-color: var(--palette-accent-500);
  --mdc-outlined-text-field-caret-color: var(--palette-accent-500);
  --mdc-outlined-text-field-focus-outline-color: var(--palette-accent-500);
  --mdc-outlined-text-field-focus-label-text-color: var(--palette-accent-500);
  --mat-form-field-focus-select-arrow-color: var(--palette-accent-500);
}
.isDarkTheme .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: var(--palette-warn-500);
  --mdc-filled-text-field-focus-active-indicator-color: var(--palette-warn-500);
  --mdc-filled-text-field-focus-label-text-color: var(--palette-warn-500);
  --mdc-outlined-text-field-caret-color: var(--palette-warn-500);
  --mdc-outlined-text-field-focus-outline-color: var(--palette-warn-500);
  --mdc-outlined-text-field-focus-label-text-color: var(--palette-warn-500);
  --mat-form-field-focus-select-arrow-color: var(--palette-warn-500);
}
.isDarkTheme .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: var(--palette-accent-500);
  --mat-select-invalid-arrow-color: var(--palette-warn-500);
}
.isDarkTheme .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: var(--palette-warn-500);
  --mat-select-invalid-arrow-color: var(--palette-warn-500);
}
.isDarkTheme .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.isDarkTheme .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #595959;
  --mdc-chip-elevated-selected-container-color: #595959;
  --mdc-chip-elevated-disabled-container-color: #595959;
  --mdc-chip-flat-disabled-selected-container-color: #595959;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.isDarkTheme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .isDarkTheme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: var(--palette-primary-contrast-500);
  --mdc-chip-elevated-container-color: var(--palette-primary-500);
  --mdc-chip-elevated-selected-container-color: var(--palette-primary-500);
  --mdc-chip-elevated-disabled-container-color: var(--palette-primary-500);
  --mdc-chip-flat-disabled-selected-container-color: var(--palette-primary-500);
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--palette-primary-contrast-500);
  --mdc-chip-selected-label-text-color: var(--palette-primary-contrast-500);
  --mdc-chip-with-icon-icon-color: var(--palette-primary-contrast-500);
  --mdc-chip-with-icon-disabled-icon-color: var(--palette-primary-contrast-500);
  --mdc-chip-with-icon-selected-icon-color: var(--palette-primary-contrast-500);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--palette-primary-contrast-500);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--palette-primary-contrast-500);
  --mat-chip-selected-disabled-trailing-icon-color: var(--palette-primary-contrast-500);
  --mat-chip-selected-trailing-icon-color: var(--palette-primary-contrast-500);
}
.isDarkTheme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .isDarkTheme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: var(--palette-accent-contrast-500);
  --mdc-chip-elevated-container-color: var(--palette-accent-500);
  --mdc-chip-elevated-selected-container-color: var(--palette-accent-500);
  --mdc-chip-elevated-disabled-container-color: var(--palette-accent-500);
  --mdc-chip-flat-disabled-selected-container-color: var(--palette-accent-500);
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--palette-accent-contrast-500);
  --mdc-chip-selected-label-text-color: var(--palette-accent-contrast-500);
  --mdc-chip-with-icon-icon-color: var(--palette-accent-contrast-500);
  --mdc-chip-with-icon-disabled-icon-color: var(--palette-accent-contrast-500);
  --mdc-chip-with-icon-selected-icon-color: var(--palette-accent-contrast-500);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--palette-accent-contrast-500);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--palette-accent-contrast-500);
  --mat-chip-selected-disabled-trailing-icon-color: var(--palette-accent-contrast-500);
  --mat-chip-selected-trailing-icon-color: var(--palette-accent-contrast-500);
}
.isDarkTheme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .isDarkTheme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: var(--palette-warn-contrast-500);
  --mdc-chip-elevated-container-color: var(--palette-warn-500);
  --mdc-chip-elevated-selected-container-color: var(--palette-warn-500);
  --mdc-chip-elevated-disabled-container-color: var(--palette-warn-500);
  --mdc-chip-flat-disabled-selected-container-color: var(--palette-warn-500);
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--palette-warn-contrast-500);
  --mdc-chip-selected-label-text-color: var(--palette-warn-contrast-500);
  --mdc-chip-with-icon-icon-color: var(--palette-warn-contrast-500);
  --mdc-chip-with-icon-disabled-icon-color: var(--palette-warn-contrast-500);
  --mdc-chip-with-icon-selected-icon-color: var(--palette-warn-contrast-500);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--palette-warn-contrast-500);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--palette-warn-contrast-500);
  --mat-chip-selected-disabled-trailing-icon-color: var(--palette-warn-contrast-500);
  --mat-chip-selected-trailing-icon-color: var(--palette-warn-contrast-500);
}
.isDarkTheme .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.isDarkTheme .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--palette-accent-300);
  --mdc-switch-selected-handle-color: var(--palette-accent-300);
  --mdc-switch-selected-hover-state-layer-color: var(--palette-accent-300);
  --mdc-switch-selected-pressed-state-layer-color: var(--palette-accent-300);
  --mdc-switch-selected-focus-handle-color: var(--palette-accent-200);
  --mdc-switch-selected-hover-handle-color: var(--palette-accent-200);
  --mdc-switch-selected-pressed-handle-color: var(--palette-accent-200);
  --mdc-switch-selected-focus-track-color: var(--palette-accent-600);
  --mdc-switch-selected-hover-track-color: var(--palette-accent-600);
  --mdc-switch-selected-pressed-track-color: var(--palette-accent-600);
  --mdc-switch-selected-track-color: var(--palette-accent-600);
}
.isDarkTheme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: var(--palette-warn-300);
  --mdc-switch-selected-handle-color: var(--palette-warn-300);
  --mdc-switch-selected-hover-state-layer-color: var(--palette-warn-300);
  --mdc-switch-selected-pressed-state-layer-color: var(--palette-warn-300);
  --mdc-switch-selected-focus-handle-color: var(--palette-warn-200);
  --mdc-switch-selected-hover-handle-color: var(--palette-warn-200);
  --mdc-switch-selected-pressed-handle-color: var(--palette-warn-200);
  --mdc-switch-selected-focus-track-color: var(--palette-warn-600);
  --mdc-switch-selected-hover-track-color: var(--palette-warn-600);
  --mdc-switch-selected-pressed-track-color: var(--palette-warn-600);
  --mdc-switch-selected-track-color: var(--palette-warn-600);
}
.isDarkTheme .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.isDarkTheme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--palette-primary-500);
  --mdc-radio-selected-hover-icon-color: var(--palette-primary-500);
  --mdc-radio-selected-icon-color: var(--palette-primary-500);
  --mdc-radio-selected-pressed-icon-color: var(--palette-primary-500);
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: var(--palette-primary-500);
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.isDarkTheme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--palette-accent-500);
  --mdc-radio-selected-hover-icon-color: var(--palette-accent-500);
  --mdc-radio-selected-icon-color: var(--palette-accent-500);
  --mdc-radio-selected-pressed-icon-color: var(--palette-accent-500);
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: var(--palette-accent-500);
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.isDarkTheme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--palette-warn-500);
  --mdc-radio-selected-hover-icon-color: var(--palette-warn-500);
  --mdc-radio-selected-icon-color: var(--palette-warn-500);
  --mdc-radio-selected-pressed-icon-color: var(--palette-warn-500);
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: var(--palette-warn-500);
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.isDarkTheme .mat-accent {
  --mat-slider-ripple-color: var(--palette-accent-500);
  --mat-slider-hover-state-layer-color: rgba(var(--palette-accent-500), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--palette-accent-500), 0.2);
  --mdc-slider-handle-color: var(--palette-accent-500);
  --mdc-slider-focus-handle-color: var(--palette-accent-500);
  --mdc-slider-hover-handle-color: var(--palette-accent-500);
  --mdc-slider-active-track-color: var(--palette-accent-500);
  --mdc-slider-inactive-track-color: var(--palette-accent-500);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--palette-accent-500);
  --mdc-slider-with-tick-marks-active-container-color: var(--palette-accent-contrast-500);
}
.isDarkTheme .mat-warn {
  --mat-slider-ripple-color: var(--palette-warn-500);
  --mat-slider-hover-state-layer-color: rgba(var(--palette-warn-500), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--palette-warn-500), 0.2);
  --mdc-slider-handle-color: var(--palette-warn-500);
  --mdc-slider-focus-handle-color: var(--palette-warn-500);
  --mdc-slider-hover-handle-color: var(--palette-warn-500);
  --mdc-slider-active-track-color: var(--palette-warn-500);
  --mdc-slider-inactive-track-color: var(--palette-warn-500);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--palette-warn-500);
  --mdc-slider-with-tick-marks-active-container-color: var(--palette-warn-contrast-500);
}
.isDarkTheme .mdc-list-item__start,
.isDarkTheme .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--palette-primary-500);
  --mdc-radio-selected-hover-icon-color: var(--palette-primary-500);
  --mdc-radio-selected-icon-color: var(--palette-primary-500);
  --mdc-radio-selected-pressed-icon-color: var(--palette-primary-500);
}
.isDarkTheme .mat-accent .mdc-list-item__start,
.isDarkTheme .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--palette-accent-500);
  --mdc-radio-selected-hover-icon-color: var(--palette-accent-500);
  --mdc-radio-selected-icon-color: var(--palette-accent-500);
  --mdc-radio-selected-pressed-icon-color: var(--palette-accent-500);
}
.isDarkTheme .mat-warn .mdc-list-item__start,
.isDarkTheme .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--palette-warn-500);
  --mdc-radio-selected-hover-icon-color: var(--palette-warn-500);
  --mdc-radio-selected-icon-color: var(--palette-warn-500);
  --mdc-radio-selected-pressed-icon-color: var(--palette-warn-500);
}
.isDarkTheme .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--palette-primary-500);
  --mdc-checkbox-selected-hover-icon-color: var(--palette-primary-500);
  --mdc-checkbox-selected-icon-color: var(--palette-primary-500);
  --mdc-checkbox-selected-pressed-icon-color: var(--palette-primary-500);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--palette-primary-500);
  --mdc-checkbox-selected-hover-state-layer-color: var(--palette-primary-500);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--palette-primary-500);
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.isDarkTheme .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--palette-accent-500);
  --mdc-checkbox-selected-hover-icon-color: var(--palette-accent-500);
  --mdc-checkbox-selected-icon-color: var(--palette-accent-500);
  --mdc-checkbox-selected-pressed-icon-color: var(--palette-accent-500);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--palette-accent-500);
  --mdc-checkbox-selected-hover-state-layer-color: var(--palette-accent-500);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--palette-accent-500);
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.isDarkTheme .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--palette-warn-500);
  --mdc-checkbox-selected-hover-icon-color: var(--palette-warn-500);
  --mdc-checkbox-selected-icon-color: var(--palette-warn-500);
  --mdc-checkbox-selected-pressed-icon-color: var(--palette-warn-500);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--palette-warn-500);
  --mdc-checkbox-selected-hover-state-layer-color: var(--palette-warn-500);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--palette-warn-500);
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.isDarkTheme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.isDarkTheme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--palette-primary-500);
}
.isDarkTheme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.isDarkTheme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--palette-primary-500);
}
.isDarkTheme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.isDarkTheme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.isDarkTheme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.isDarkTheme .mat-mdc-tab-group, .isDarkTheme .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--palette-primary-500);
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: var(--palette-primary-500);
  --mat-tab-header-active-ripple-color: var(--palette-primary-500);
  --mat-tab-header-inactive-ripple-color: var(--palette-primary-500);
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--palette-primary-500);
  --mat-tab-header-active-hover-label-text-color: var(--palette-primary-500);
  --mat-tab-header-active-focus-indicator-color: var(--palette-primary-500);
  --mat-tab-header-active-hover-indicator-color: var(--palette-primary-500);
}
.isDarkTheme .mat-mdc-tab-group.mat-accent, .isDarkTheme .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: var(--palette-accent-500);
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: var(--palette-accent-500);
  --mat-tab-header-active-ripple-color: var(--palette-accent-500);
  --mat-tab-header-inactive-ripple-color: var(--palette-accent-500);
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--palette-accent-500);
  --mat-tab-header-active-hover-label-text-color: var(--palette-accent-500);
  --mat-tab-header-active-focus-indicator-color: var(--palette-accent-500);
  --mat-tab-header-active-hover-indicator-color: var(--palette-accent-500);
}
.isDarkTheme .mat-mdc-tab-group.mat-warn, .isDarkTheme .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: var(--palette-warn-500);
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: var(--palette-warn-500);
  --mat-tab-header-active-ripple-color: var(--palette-warn-500);
  --mat-tab-header-inactive-ripple-color: var(--palette-warn-500);
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--palette-warn-500);
  --mat-tab-header-active-hover-label-text-color: var(--palette-warn-500);
  --mat-tab-header-active-focus-indicator-color: var(--palette-warn-500);
  --mat-tab-header-active-hover-indicator-color: var(--palette-warn-500);
}
.isDarkTheme .mat-mdc-tab-group.mat-background-primary, .isDarkTheme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: var(--palette-primary-500);
  --mat-tab-header-with-background-foreground-color: var(--palette-primary-contrast-500);
}
.isDarkTheme .mat-mdc-tab-group.mat-background-accent, .isDarkTheme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: var(--palette-accent-500);
  --mat-tab-header-with-background-foreground-color: var(--palette-accent-contrast-500);
}
.isDarkTheme .mat-mdc-tab-group.mat-background-warn, .isDarkTheme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: var(--palette-warn-500);
  --mat-tab-header-with-background-foreground-color: var(--palette-warn-contrast-500);
}
.isDarkTheme .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.isDarkTheme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--palette-primary-500);
  --mdc-checkbox-selected-hover-icon-color: var(--palette-primary-500);
  --mdc-checkbox-selected-icon-color: var(--palette-primary-500);
  --mdc-checkbox-selected-pressed-icon-color: var(--palette-primary-500);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--palette-primary-500);
  --mdc-checkbox-selected-hover-state-layer-color: var(--palette-primary-500);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--palette-primary-500);
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.isDarkTheme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--palette-warn-500);
  --mdc-checkbox-selected-hover-icon-color: var(--palette-warn-500);
  --mdc-checkbox-selected-icon-color: var(--palette-warn-500);
  --mdc-checkbox-selected-pressed-icon-color: var(--palette-warn-500);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--palette-warn-500);
  --mdc-checkbox-selected-hover-state-layer-color: var(--palette-warn-500);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--palette-warn-500);
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.isDarkTheme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: var(--palette-primary-500);
  --mat-text-button-state-layer-color: var(--palette-primary-500);
  --mat-text-button-ripple-color: var(--palette-primary-500);
}
.isDarkTheme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: var(--palette-accent-500);
  --mat-text-button-state-layer-color: var(--palette-accent-500);
  --mat-text-button-ripple-color: var(--palette-accent-500);
}
.isDarkTheme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: var(--palette-warn-500);
  --mat-text-button-state-layer-color: var(--palette-warn-500);
  --mat-text-button-ripple-color: var(--palette-warn-500);
}
.isDarkTheme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: var(--palette-primary-500);
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.isDarkTheme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: var(--palette-accent-500);
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.isDarkTheme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: var(--palette-warn-500);
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.isDarkTheme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: var(--palette-primary-500);
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.isDarkTheme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: var(--palette-accent-500);
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.isDarkTheme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: var(--palette-warn-500);
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.isDarkTheme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: var(--palette-primary-500);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: var(--palette-primary-500);
  --mat-outlined-button-ripple-color: var(--palette-primary-500);
}
.isDarkTheme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: var(--palette-accent-500);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: var(--palette-accent-500);
  --mat-outlined-button-ripple-color: var(--palette-accent-500);
}
.isDarkTheme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: var(--palette-warn-500);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: var(--palette-warn-500);
  --mat-outlined-button-ripple-color: var(--palette-warn-500);
}
.isDarkTheme .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: var(--palette-primary-500);
  --mat-icon-button-state-layer-color: var(--palette-primary-500);
  --mat-icon-button-ripple-color: var(--palette-primary-500);
}
.isDarkTheme .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: var(--palette-accent-500);
  --mat-icon-button-state-layer-color: var(--palette-accent-500);
  --mat-icon-button-ripple-color: var(--palette-accent-500);
}
.isDarkTheme .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: var(--palette-warn-500);
  --mat-icon-button-state-layer-color: var(--palette-warn-500);
  --mat-icon-button-ripple-color: var(--palette-warn-500);
}
.isDarkTheme .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: var(--palette-primary-500);
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.isDarkTheme .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: var(--palette-accent-500);
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.isDarkTheme .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: var(--palette-warn-500);
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.isDarkTheme .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: var(--palette-primary-500);
  --mat-fab-small-foreground-color: #fff;
  --mat-fab-small-state-layer-color: #fff;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.isDarkTheme .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: var(--palette-accent-500);
  --mat-fab-small-foreground-color: #fff;
  --mat-fab-small-state-layer-color: #fff;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.isDarkTheme .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: var(--palette-warn-500);
  --mat-fab-small-foreground-color: #fff;
  --mat-fab-small-state-layer-color: #fff;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.isDarkTheme .mat-accent {
  --mdc-circular-progress-active-indicator-color: var(--palette-accent-500);
}
.isDarkTheme .mat-warn {
  --mdc-circular-progress-active-indicator-color: var(--palette-warn-500);
}
.isDarkTheme .mat-badge-accent {
  --mat-badge-background-color: var(--palette-accent-500);
  --mat-badge-text-color: var(--palette-accent-contrast-500);
}
.isDarkTheme .mat-badge-warn {
  --mat-badge-background-color: var(--palette-warn-500);
  --mat-badge-text-color: var(--palette-warn-contrast-500);
}
.isDarkTheme .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--palette-accent-contrast-500);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--palette-accent-500);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--palette-accent-contrast-500);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--palette-accent-500);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--palette-accent-500);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--palette-accent-500), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.isDarkTheme .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--palette-warn-contrast-500);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--palette-warn-500);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--palette-warn-contrast-500);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--palette-warn-500);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--palette-warn-500);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--palette-warn-500), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.isDarkTheme .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: var(--palette-accent-500);
}
.isDarkTheme .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: var(--palette-warn-500);
}
.isDarkTheme .mat-icon.mat-primary {
  --mat-icon-color: var(--palette-primary-500);
}
.isDarkTheme .mat-icon.mat-accent {
  --mat-icon-color: var(--palette-accent-500);
}
.isDarkTheme .mat-icon.mat-warn {
  --mat-icon-color: var(--palette-warn-500);
}
.isDarkTheme .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: var(--palette-accent-contrast-500);
  --mat-stepper-header-selected-state-icon-background-color: var(--palette-accent-500);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--palette-accent-contrast-500);
  --mat-stepper-header-done-state-icon-background-color: var(--palette-accent-500);
  --mat-stepper-header-done-state-icon-foreground-color: var(--palette-accent-contrast-500);
  --mat-stepper-header-edit-state-icon-background-color: var(--palette-accent-500);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--palette-accent-contrast-500);
}
.isDarkTheme .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: var(--palette-warn-contrast-500);
  --mat-stepper-header-selected-state-icon-background-color: var(--palette-warn-500);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--palette-warn-contrast-500);
  --mat-stepper-header-done-state-icon-background-color: var(--palette-warn-500);
  --mat-stepper-header-done-state-icon-foreground-color: var(--palette-warn-contrast-500);
  --mat-stepper-header-edit-state-icon-background-color: var(--palette-warn-500);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--palette-warn-contrast-500);
}
.isDarkTheme .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: var(--palette-primary-500);
  --mat-toolbar-container-text-color: var(--palette-primary-contrast-500);
}
.isDarkTheme .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: var(--palette-accent-500);
  --mat-toolbar-container-text-color: var(--palette-accent-contrast-500);
}
.isDarkTheme .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: var(--palette-warn-500);
  --mat-toolbar-container-text-color: var(--palette-warn-contrast-500);
}

.mat-mdc-fab:not(:disabled).mat-primary {
  --mat-fab-foreground-color: var(--palette-primary-contrast-500);
  --mat-fab-state-layer-color: var(--palette-primary-contrast-500);
}
.mat-mdc-fab:not(:disabled).mat-accent {
  --mat-fab-foreground-color: var(--palette-accent-contrast-500);
  --mat-fab-state-layer-color: var(--palette-accent-contrast-500);
}
.mat-mdc-fab:not(:disabled).mat-warn {
  --mat-fab-foreground-color: var(--palette-warn-contrast-500);
  --mat-fab-state-layer-color: var(--palette-warn-contrast-500);
}

.mat-mdc-mini-fab:not(:disabled).mat-primary {
  --mat-fab-small-foreground-color: var(--palette-primary-contrast-500);
  --mat-fab-small-state-layer-color: var(--palette-primary-contrast-500);
}
.mat-mdc-mini-fab:not(:disabled).mat-accent {
  --mat-fab-small-foreground-color: var(--palette-accent-contrast-500);
  --mat-fab-small-state-layer-color: var(--palette-accent-contrast-500);
}
.mat-mdc-mini-fab:not(:disabled).mat-warn {
  --mat-fab-small-foreground-color: var(--palette-warn-contrast-500);
  --mat-fab-small-state-layer-color: var(--palette-warn-contrast-500);
}

html .mat-mdc-icon-button.mat-primary, .isDarkTheme .mat-mdc-icon-button.mat-primary {
  --mat-icon-button-ripple-color: rgba(var(--palette-primary-500-no-rgb), 0.1);
}
html .mat-mdc-icon-button.mat-accent, .isDarkTheme .mat-mdc-icon-button.mat-accent {
  --mat-icon-button-ripple-color: rgba(var(--palette-accent-500-no-rgb), 0.1);
}
html .mat-mdc-icon-button.mat-warn, .isDarkTheme .mat-mdc-icon-button.mat-warn {
  --mat-icon-button-ripple-color: rgba(var(--palette-warn-500-no-rgb), 0.1);
}

.mat-mdc-button.mat-primary, .isDarkTheme .mat-mdc-button.mat-primary {
  --mat-text-button-ripple-color: rgba(var(--palette-primary-500-no-rgb), 0.1);
}
.mat-mdc-button.mat-accent, .isDarkTheme .mat-mdc-button.mat-accent {
  --mat-text-button-ripple-color: rgba(var(--palette-accent-500-no-rgb), 0.1);
}
.mat-mdc-button.mat-warn, .isDarkTheme .mat-mdc-button.mat-warn {
  --mat-text-button-ripple-color: rgba(var(--palette-warn-500-no-rgb), 0.1);
}

.mat-mdc-outlined-button.mat-primary, .isDarkTheme .mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-ripple-color: rgba(var(--palette-primary-500-no-rgb), 0.1);
}
.mat-mdc-outlined-button.mat-accent, .isDarkTheme .mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-ripple-color: rgba(var(--palette-accent-500-no-rgb), 0.1);
}
.mat-mdc-outlined-button.mat-warn, .isDarkTheme .mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-ripple-color: rgba(var(--palette-warn-500-no-rgb), 0.1);
}

.mat-mdc-unelevated-button:not(:disabled).mat-primary,
.mat-mdc-raised-button:not(:disabled).mat-primary {
  --mdc-filled-button-label-text-color: var(--palette-primary-contrast-500);
  --mdc-protected-button-label-text-color: var(--palette-primary-contrast-500);
}
.mat-mdc-unelevated-button:not(:disabled).mat-accent,
.mat-mdc-raised-button:not(:disabled).mat-accent {
  --mdc-filled-button-label-text-color: var(--palette-accent-contrast-500);
  --mdc-protected-button-label-text-color: var(--palette-accent-contrast-500);
}
.mat-mdc-unelevated-button:not(:disabled).mat-warn,
.mat-mdc-raised-button:not(:disabled).mat-warn {
  --mdc-filled-button-label-text-color: var(--palette-warn-contrast-500);
  --mdc-protected-button-label-text-color: var(--palette-warn-contrast-500);
}

.mat-mdc-form-field.mat-primary .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(var(--palette-primary-500-no-rgb), 0.87);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(var(--palette-accent-500-no-rgb), 0.87);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(var(--palette-warn-500-no-rgb), 0.87);
}

.isLightTheme .mat-mdc-slide-toggle.mat-primary, .isLightTheme .mat-mdc-slide-toggle.mat-accent, .isLightTheme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-unselected-icon-color: white;
}
.isDarkTheme .mat-mdc-slide-toggle.mat-primary, .isDarkTheme .mat-mdc-slide-toggle.mat-accent, .isDarkTheme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-unselected-icon-color: black;
}
.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-icon-color: var(--palette-primary-contrast-500);
  --mdc-switch-disabled-selected-icon-color: var(--palette-primary-contrast-500);
  --mdc-switch-disabled-unselected-icon-color: var(--palette-primary-contrast-500);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-icon-color: var(--palette-accent-contrast-500);
  --mdc-switch-disabled-selected-icon-color: var(--palette-accent-contrast-500);
  --mdc-switch-disabled-unselected-icon-color: var(--palette-accent-contrast-500);
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-icon-color: var(--palette-warn-contrast-500);
  --mdc-switch-disabled-selected-icon-color: var(--palette-warn-contrast-500);
  --mdc-switch-disabled-unselected-icon-color: var(--palette-warn-contrast-500);
  --mdc-switch-unselected-icon-color: var(--palette-warn-contrast-500);
}

.mat-mdc-checkbox.mat-primary, .isDarkTheme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: var(--palette-primary-contrast-500);
}
.mat-mdc-checkbox.mat-accent, .isDarkTheme .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: var(--palette-accent-contrast-500);
}
.mat-mdc-checkbox.mat-warn, .isDarkTheme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: var(--palette-warn-contrast-500);
}

.mat-datepicker-content.mat-primary .mat-calendar-body-in-range::before {
  background: rgba(var(--palette-primary-500-no-rgb), 0.2);
}
.mat-datepicker-content.mat-primary .mat-calendar-body-cell:not(.mat-calendar-body-disabled).mat-calendar-body-active > .mat-calendar-body-today {
  color: var(--palette-primary-contrast-500);
}
.mat-datepicker-content.mat-primary .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background: rgba(var(--palette-primary-500-no-rgb), 0.3);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(var(--palette-accent-500-no-rgb), 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled).mat-calendar-body-active > .mat-calendar-body-today {
  color: var(--palette-accent-contrast-500);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background: rgba(var(--palette-accent-500-no-rgb), 0.3);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(var(--palette-warn-500-no-rgb), 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled).mat-calendar-body-active > .mat-calendar-body-today {
  color: var(--palette-warn-contrast-500);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background: rgba(var(--palette-warn-500-no-rgb), 0.3);
}

.mat-mdc-progress-bar.mat-primary .mdc-linear-progress__buffer-bar {
  background-color: rgba(var(--palette-primary-500-no-rgb), 0.25);
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(var(--palette-accent-500-no-rgb), 0.25);
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(var(--palette-warn-500-no-rgb), 0.25);
}

.mat-mdc-slider.mat-primary {
  --mat-mdc-slider-hover-ripple-color: rgba(var(--palette-primary-500-no-rgb), 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(var(--palette-primary-500-no-rgb), 0.2);
}
.mat-mdc-slider.mat-accent {
  --mat-mdc-slider-hover-ripple-color: rgba(var(--palette-accent-500-no-rgb), 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(var(--palette-accent-500-no-rgb), 0.2);
}
.mat-mdc-slider.mat-warn {
  --mat-mdc-slider-hover-ripple-color: rgba(var(--palette-warn-500-no-rgb), 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(var(--palette-warn-500-no-rgb), 0.2);
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  min-height: -webkit-fill-available;
}

#app-native-container {
  display: flex;
  position: relative;
}

.native-error-body-adaption {
  margin: 0;
  padding: 0 1rem;
  text-align: center;
  background: #121212;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.native-error-body-adaption #app-native-container {
  width: 100%;
}

.native-error-container {
  width: 100%;
  max-width: 20rem;
  height: auto;
  display: block;
  margin: 0 auto;
  padding: 2rem;
  color: #121212;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  overflow-wrap: break-word;
}

.native-error-image {
  max-width: 4rem;
  margin: 0 auto;
}
.native-error-image img,
.native-error-image svg {
  max-width: 100%;
  height: auto;
  display: block;
}

.native-error-message {
  font-size: 1rem;
  margin: 1rem 0 0 0;
}

.mat-mdc-dialog-container {
  margin-right: 1rem;
  margin-left: 1rem;
}

.mat-mdc-dialog-container .mat-body-2 {
  color: #121212;
}

.isDarkTheme .mat-mdc-dialog-container .mat-body-2 {
  color: #ffffff;
}

.mat-mdc-dialog-content {
  max-height: 55vh !important;
}
@media screen and (min-width: 600px) {
  .mat-mdc-dialog-content {
    max-height: 65vh !important;
    min-width: 100%;
  }
}

.mat-mdc-dialog-title,
.mat-mdc-dialog-content {
  font-family: var(--theme-font-body-family, "Roboto", sans-serif);
}

.mat-mdc-dialog-content .ui .svg-inline--fa {
  vertical-align: 0 !important;
}

.mat-mdc-input-element,
.mdc-label,
.mdc-floating-label,
.mat-mdc-form-field-hint,
.mat-mdc-select-value-text {
  font-family: var(--theme-font-body-family, "Roboto", sans-serif) !important;
  color: var(--theme-body-color) !important;
}

.mat-mdc-form-field-error {
  font-family: var(--theme-font-body-family, "Roboto", sans-serif) !important;
}

.mat-mdc-form-field {
  margin-top: calc(1rem * 0.25) !important;
  margin-bottom: calc(1rem * 0.5) !important;
}

.mat-mdc-form-field-type-checkbox .mdc-notched-outline,
.mat-mdc-form-field-type-toggle .mdc-notched-outline {
  display: none;
}
.mat-mdc-form-field-type-checkbox .mat-mdc-form-field-infix,
.mat-mdc-form-field-type-toggle .mat-mdc-form-field-infix {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: auto !important;
}

div.mat-mdc-select-panel {
  min-width: 300px !important;
}

:host {
  margin-bottom: calc(1rem * 0.25);
  flex-grow: 1;
  padding: calc(1rem * 0.5);
  border-radius: var(--theme-radius, 4px 4px 4px 4px);
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

:host-context(.isDarkTheme) {
  background-color: rgba(18, 18, 18, 0.3);
}

:host {
  margin-bottom: calc(1rem * 0.5);
}
@media print {
  :host {
    display: none !important;
  }
}
:host .mat-mdc-button,
:host .mat-mdc-unelevated-button,
:host .mat-mdc-outlined-button,
:host .mat-mdc-raised-button {
  font-family: var(--theme-font-body-family, "Roboto", sans-serif);
  font-size: var(--theme-font-body-size, 1rem);
  font-weight: var(--theme-font-button-weight, bold);
  border-radius: var(--theme-radius, 4px 4px 4px 4px);
  letter-spacing: normal;
}
:host .mat-flat-button.mat-primary:not([disabled]), :host .mat-flat-button.mat-accent:not([disabled]),
:host .mat-raised-button.mat-primary:not([disabled]),
:host .mat-raised-button.mat-accent:not([disabled]),
:host .mat-stroked-button.mat-primary:not([disabled]),
:host .mat-stroked-button.mat-accent:not([disabled]),
:host .mat-fab.mat-primary:not([disabled]),
:host .mat-fab.mat-accent:not([disabled]),
:host .mat-accent.mat-primary:not([disabled]),
:host .mat-accent.mat-accent:not([disabled]),
:host .mat-mini-fab.mat-primary:not([disabled]),
:host .mat-mini-fab.mat-accent:not([disabled]) {
  color: var(--theme-font-button-label-color, #ffffff);
}
:host .mat-mdc-mini-fab.mat-primary:not([disabled]) {
  color: var(--theme-font-button-label-color, #121212);
}
:host .mat-icon {
  width: unset;
  height: unset;
  overflow: unset;
  font-size: 90%;
}
:host .mat-mdc-fab .mat-icon {
  font-size: 1.5rem;
}
:host .mat-icon-left {
  margin-right: calc(1rem * 0.25);
  text-align: left;
}
:host .mat-icon-right {
  margin-left: calc(1rem * 0.25);
  text-align: right;
}

.mat-mdc-outlined-button {
  background-color: rgba(255, 255, 255, 0.15);
}

.mat-button-textured {
  background-image: var(--theme-textured-button-url);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

:host-context(.isDarkTheme) .mat-flat-button.mat-primary:not([disabled]), :host-context(.isDarkTheme) .mat-flat-button.mat-accent:not([disabled]),
:host-context(.isDarkTheme) .mat-raised-button.mat-primary:not([disabled]),
:host-context(.isDarkTheme) .mat-raised-button.mat-accent:not([disabled]),
:host-context(.isDarkTheme) .mat-stroked-button.mat-primary:not([disabled]),
:host-context(.isDarkTheme) .mat-stroked-button.mat-accent:not([disabled]),
:host-context(.isDarkTheme) .mat-fab.mat-primary:not([disabled]),
:host-context(.isDarkTheme) .mat-fab.mat-accent:not([disabled]),
:host-context(.isDarkTheme) .mat-accent.mat-primary:not([disabled]),
:host-context(.isDarkTheme) .mat-accent.mat-accent:not([disabled]),
:host-context(.isDarkTheme) .mat-mini-fab.mat-primary:not([disabled]),
:host-context(.isDarkTheme) .mat-mini-fab.mat-accent:not([disabled]) {
  color: var(--theme-font-button-label-color, #121212);
}
:host-context(.isDarkTheme) .mat-mdc-mini-fab.mat-primary:not([disabled]) {
  color: var(--theme-font-button-label-color, #121212);
}

:host-context(.mat-footer) .mat-mdc-button-base {
  font-size: 12px;
  line-height: 16px;
  padding: 0.5rem 1rem;
}

:host-context(.buttonContainerRow) {
  margin-bottom: calc(1rem * 0.5);
}
@media screen and (min-width: 600px) {
  :host-context(.buttonContainerRow) {
    margin-bottom: calc(1rem);
  }
}
@media screen and (min-width: 600px) {
  :host-context(.buttonContainerRow) button,
  :host-context(.buttonContainerRow) a {
    width: auto;
  }
}

:host-context(.isStacked) {
  width: 100%;
  margin-bottom: calc(1rem * 0.5);
}
@media screen and (min-width: 600px) {
  :host-context(.isStacked) {
    max-width: 400px;
  }
}
:host-context(.isStacked) button,
:host-context(.isStacked) a {
  width: 100%;
}

:host-context(.fixed-size) {
  display: flex;
  width: 100%;
}
:host-context(.fixed-size) button,
:host-context(.fixed-size) a {
  width: 100%;
}
@media screen and (min-width: 600px) {
  :host-context(.fixed-size) button,
  :host-context(.fixed-size) a {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

:host-context(.full-size) {
  width: 100%;
}
:host-context(.full-size) button,
:host-context(.full-size) a {
  width: 100%;
}

.mat-mdc-snack-bar-action {
  color: #ffffff !important;
}

.isDarkTheme .mat-mdc-snack-bar-action {
  color: #121212 !important;
}

.mat-typography {
  font-family: var(--theme-font-body-family, "Roboto", sans-serif);
  font-weight: var(--theme-font-headline-weight, 400);
  color: var(--theme-body-color, #121212);
}

:host-context(.isDarkTheme) .mat-typography {
  color: var(--theme-body-color, #ffffff);
}

.mat-headline-5,
.mat-typography .mat-headline {
  color: var(--theme-font-headline-color);
  font-family: var(--theme-font-headline-family, "Roboto", sans-serif);
  font-weight: var(--theme-font-headline-weight, 700);
  font-size: var(--theme-font-headline-size, 1.75rem);
  width: 100%;
  height: auto;
  display: block;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  margin-bottom: calc(1rem);
  word-break: break-word;
  text-align: center;
}
.mat-headline-5[lang=zh], .mat-headline-5[lang=ja], .mat-headline-5[lang=ko],
.mat-typography .mat-headline[lang=zh],
.mat-typography .mat-headline[lang=ja],
.mat-typography .mat-headline[lang=ko] {
  word-break: keep-all;
}
@media screen and (min-width: 600px) {
  .mat-headline-5,
  .mat-typography .mat-headline {
    font-size: var(--theme-font-headline-size, 2rem);
  }
}
@media print {
  .mat-headline-5,
  .mat-typography .mat-headline {
    color: black !important;
  }
}

.mat-mdc-dialog-title,
.mat-typography .mat-dialog-title {
  word-break: break-word;
  text-align: center;
}
.mat-mdc-dialog-title[lang=zh], .mat-mdc-dialog-title[lang=ja], .mat-mdc-dialog-title[lang=ko],
.mat-typography .mat-dialog-title[lang=zh],
.mat-typography .mat-dialog-title[lang=ja],
.mat-typography .mat-dialog-title[lang=ko] {
  word-break: keep-all;
}

.mat-body-2,
.mat-typography .mat-body-2 {
  color: var(--theme-body-color);
  font-family: var(--theme-font-body-family, "Roboto", sans-serif);
  font-weight: var(--theme-font-body-weight, 400);
  font-size: var(--theme-font-body-size, 1rem);
  text-align: var(--theme-font-body-align, center);
  word-break: break-word;
  margin-bottom: calc(1rem);
}
.mat-body-2[lang=zh], .mat-body-2[lang=ja], .mat-body-2[lang=ko],
.mat-typography .mat-body-2[lang=zh],
.mat-typography .mat-body-2[lang=ja],
.mat-typography .mat-body-2[lang=ko] {
  word-break: keep-all;
}
@media print {
  .mat-body-2,
  .mat-typography .mat-body-2 {
    color: black !important;
  }
}

.mat-mdc-table .mat-body-2 {
  margin-bottom: 0;
}

a {
  color: var(--theme-body-color);
}

.mat-caption,
.mat-typography .mat-caption {
  color: var(--theme-body-color);
  font-weight: var(--theme-font-body-weight, 400);
  font-family: var(--theme-font-body-family, "Roboto", sans-serif);
  width: 100%;
  height: auto;
  display: block;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  margin-bottom: calc(1rem);
  text-align: center;
  line-height: 1.25;
  word-break: break-word;
}
.mat-caption[lang=zh], .mat-caption[lang=ja], .mat-caption[lang=ko],
.mat-typography .mat-caption[lang=zh],
.mat-typography .mat-caption[lang=ja],
.mat-typography .mat-caption[lang=ko] {
  word-break: keep-all;
}
@media print {
  .mat-caption,
  .mat-typography .mat-caption {
    color: black !important;
  }
}

strong,
b {
  font-weight: 700;
}

.mat-drawer-container {
  background-color: var(--theme-background-background, #ffffff);
}

.isDarkTheme .mat-drawer-container {
  background-color: var(--theme-background-background, #121212);
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: grey !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.isDarkTheme .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: grey !important;
}