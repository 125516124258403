@import "global/variables";

:host {
    //@include spacer;
    margin-bottom: calc(#{$spacer} * 0.5);

    @media print {
        display: none !important;
    }

    .mat-mdc-button,
    .mat-mdc-unelevated-button,
    .mat-mdc-outlined-button,
    .mat-mdc-raised-button {
        font-family: var(--theme-font-body-family, $mat-font-family);
        font-size: var(--theme-font-body-size, $mat-body-font-size);
        font-weight: var(--theme-font-button-weight, bold);
        border-radius: var(--theme-radius, $mat-radius);
        letter-spacing: normal;
    }

    .mat-flat-button,
    .mat-raised-button,
    .mat-stroked-button,
    .mat-fab,
    .mat-accent,
    .mat-mini-fab {
        &.mat-primary:not([disabled]),
        &.mat-accent:not([disabled]) {
            color: var(--theme-font-button-label-color, $mat-light-color);
        }
    }
    .mat-mdc-mini-fab{
        &.mat-primary:not([disabled]){
            color: var(--theme-font-button-label-color, $mat-dark-color);
        }
    }

    .mat-icon {
        width: unset;
        height: unset;
        overflow: unset;
        font-size: 90%;
    }

    .mat-mdc-fab .mat-icon {
        font-size: 1.5rem;
    }

    .mat-icon-left {
        margin-right: calc(#{$spacer} * 0.25);
        text-align: left;
    }

    .mat-icon-right {
        margin-left: calc(#{$spacer} * 0.25);
        text-align: right;
    }
}

.mat-mdc-outlined-button {
    background-color: rgba($color: white, $alpha: 0.15);
}

// Mat button textured

.mat-button-textured {
    background-image: var(--theme-textured-button-url);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

// Label color for dark theme

:host-context(.isDarkTheme) {
    .mat-flat-button,
    .mat-raised-button,
    .mat-stroked-button,
    .mat-fab,
    .mat-accent,
    .mat-mini-fab {
        &.mat-primary:not([disabled]),
        &.mat-accent:not([disabled]) {
            color: var(--theme-font-button-label-color, $mat-dark-color);
        }
    }
    .mat-mdc-mini-fab{
        &.mat-primary:not([disabled]){
            color: var(--theme-font-button-label-color, $mat-dark-color);
        }
    }
}

// Mat button modifier for footer compatibility

:host-context(.mat-footer) .mat-mdc-button-base {
    font-size: 12px;
    line-height: 16px;
    padding: 0.5rem 1rem;
}

// button-container-row component responsiveness behavior
:host-context(.buttonContainerRow) {
    margin-bottom: calc(#{$spacer} * 0.5);

    @include breakpoint {
        @include spacer;
    }

    button,
    a {
        //  @include block;

        @include breakpoint {
            width: auto;
        }
    }
}

// isStacked buttons container default behavior
:host-context(.isStacked) {
    // @include block;
    width: 100%;
    margin-bottom: calc(#{$spacer} * 0.5);

    @include breakpoint {
        max-width: $mat-button-max-width;
    }

    button,
    a {
        width: 100%;
        //  @include block;
    }
}

// Fixed size buttons with a max width
:host-context(.fixed-size) {
    // @include block;
    display: flex;
    width: 100%;

    button,
    a {
        //  @include block;
        width: 100%;

        @include breakpoint {
            max-width: $mat-button-max-width;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

// Full size buttons with no max width
:host-context(.full-size) {
    // @include block;
    width: 100%;

    button,
    a {
        //    @include block;
        width: 100%;
    }
}
