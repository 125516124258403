// Default background color change from Material
// Background color change based on css vars
.mat-drawer-container {
    background-color: var(--theme-background-background, $mat-light-color);
}

.isDarkTheme .mat-drawer-container {
    background-color: var(--theme-background-background, $mat-dark-color);
}

// Mat progress bar buffer in the top loading bar

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background-color: grey !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.isDarkTheme .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background-color: grey !important;
}
