//@include mat.all-component-typographies($custom-typography);

// Global typography rules
.mat-typography {
    font-family: var(--theme-font-body-family, $mat-font-family);
    font-weight: var(--theme-font-headline-weight, $mat-body-font-weight);
    color: var(--theme-body-color, $mat-dark-color);
}

:host-context(.isDarkTheme) {
    .mat-typography {
        color: var(--theme-body-color, $mat-light-color);
    }
}

// Default titles
.mat-headline-5,
.mat-typography .mat-headline {
    color: var(--theme-font-headline-color);
    font-family: var(--theme-font-headline-family, $mat-font-family);
    font-weight: var(--theme-font-headline-weight, $mat-headline-font-weight);
    font-size: var(--theme-font-headline-size, 1.75rem);
    @include block;
    @include spacer;
    @include word-break;
    text-align: center;

    @include breakpoint {
        font-size: var(--theme-font-headline-size, 2rem);
    }

    @media print {
        color: black !important;
    }
}

.mat-mdc-dialog-title,
.mat-typography .mat-dialog-title {
    @include word-break;
    text-align: center;
}

///////////////////////////////////////
// Default body content
.mat-body-2,
.mat-typography .mat-body-2 {
    color: var(--theme-body-color);
    font-family: var(--theme-font-body-family, $mat-font-family);
    font-weight: var(--theme-font-body-weight, $mat-body-font-weight);
    font-size: var(--theme-font-body-size, $mat-body-font-size);
    text-align: var(--theme-font-body-align, center);
    @include word-break;
    //@include block;
    @include spacer;

    @media print {
        color: black !important;
    }
}

// Exception for the leaderboard
.mat-mdc-table .mat-body-2 {
    margin-bottom: 0;
}

// Default links
a {
    color: var(--theme-body-color);
}

// Default caption
.mat-caption,
.mat-typography .mat-caption {
    color: var(--theme-body-color);
    font-weight: var(--theme-font-body-weight, $mat-body-font-weight);
    font-family: var(--theme-font-body-family, $mat-font-family);
    @include block;
    @include spacer;
    text-align: center;
    line-height: 1.25;
    @include word-break;

    @media print {
        color: black !important;
    }
}

// Extras
strong,
b {
    font-weight: 700;
}
